import type { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';

const ignoreTwinMacroFiles = (content: string) => {
  if (content.match(/twin\.macro/)) {
    return '';
  }

  return content;
};

export default {
  content: {
    files: [
      './src/{api,assets,components,constants,context,gatsby,hooks,layouts,pages,styles,templates,utils}/**/*.{js,jsx,ts,tsx}',
    ],
    transform: {
      ts: ignoreTwinMacroFiles,
      tsx: ignoreTwinMacroFiles,
    },
  },
  plugins: [
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.container-stack-context': {
          position: 'relative',
          zIndex: '1',
        },
      });
    }),
  ],
  theme: {
    container: {
      center: true,
    },
    extend: {
      backgroundImage: {
        'range-thumb-slider-colored': "url('/assets/range-thumb-slider.svg')",
        'range-thumb-slider-red': "url('/assets/range-thumb-slider-red.svg')",
        'range-thumb-slider-blue': "url('/assets/range-thumb-slider-blue.svg')",
        'light-linear-red-grad': `linear-gradient(270deg, rgba(253, 52, 86, 0.10) 0.03%, rgba(253, 52, 86, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
        'light-linear-blue-grad': `linear-gradient(270deg, rgba(60, 130, 234, 0.10) 0.03%, rgba(60, 130, 234, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
        'light-linear-purple-grad': `linear-gradient(270deg, rgba(223, 83, 255, 0.10) 0.03%, rgba(223, 83, 255, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
        'light-linear-red-grad-vertical': `linear-gradient(180deg, rgba(253, 52, 86, 0.10) 0.03%, rgba(253, 52, 86, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
        'light-linear-blue-grad-vertical': `linear-gradient(180deg, rgba(60, 130, 234, 0.10) 0.03%, rgba(60, 130, 234, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
        'light-linear-purple-grad-vertical': `linear-gradient(180deg, rgba(223, 83, 255, 0.10) 0.03%, rgba(223, 83, 255, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
        'linear-sonar': `linear-gradient(81deg, #fd3456 0%, #df53ff 45.5%, #65d1ff 100%)`,
      },
      boxShadow: {
        smallReversed:
          '0px 4px 20px 0px rgba(253, 52, 86, 0.15), 0px 0px 32px 0px rgba(223, 83, 255, 0.15)',
        small:
          '0px 0px 32px 0px rgba(223, 83, 255, 0.15), 0px 4px 20px 0px rgba(253, 52, 86, 0.15)',
        medium:
          '0px 0px 32px 0px rgba(223, 83, 255, 0.15), 0px 4px 20px 0px rgba(253, 52, 86, 0.15)',
        large:
          '0px 0px 64px 0px rgba(223, 83, 255, 0.15), 0px 17.73529px 17.73529px 0px rgba(253, 52, 86, 0.15)',
      },
      dropShadow: {
        small: ['0px 0px 32px rgba(223, 83, 255, 0.15)', '0px 4px 20px rgba(253, 52, 86, 0.15)'],
        medium: ['0px 0px 32px rgba(223, 83, 255, 0.15)', '0px 4px 20px rgba(253, 52, 86, 0.15)'],
        large: [
          '0px 0px 64px rgba(223, 83, 255, 0.15)',
          '0px 17.73529px 17.73529px rgba(253, 52, 86, 0.15)',
        ],
      },
    },
    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      2: '2px',
      3: '3px',
      4: '4px',
      6: '6px',
      8: '8px',
    },
    colors: {
      primary: {
        purple: '#290042',
        red: '#FD3456',
        pink: '#FFF6F7',
      },
      secondary: {
        's1-blue': '#65D1FF',
        's2-blue': '#2228A4',
        's3-purple': '#DF53FF',
        's4-purple': '#39057B',
      },
      'ui-colours': {
        ui1: '#B29CBF',
        'ui2-red': '#FFEBEE',
        'ui2-purple': '#FCE8FF',
        'ui2-blue': '#DEF5FF',
        'red-muted': '#EEC8D2',
        'blue-muted': '#D0EDFC',
      },
      Neutrals: {
        n1: '#FFFFFF',
        n2: '#F4F7FB',
        n3: '#E8EFF7',
        n4: '#DDE6F3',
        n5: '#D3DCE9',
        n6: '#A8B3C2',
        n7: '#5F656D',
        n8: '#3B3F44',
        n9: '#000000',
      },
      utils: {
        transparent: '#0000ffff',
      },
    },
    fontFamily: {
      saira: ['Saira', 'Saira-Fallback'],
      'maven-pro': ['Maven Pro', 'Maven-Pro-Fallback'],
    },
    fontSize: {
      'display-xxl': [
        '80px',
        {
          letterSpacing: '2px',
          lineHeight: '88px',
        },
      ],
      'display-xl': ['56px', '64px'],
      'heading-l': ['40px', '48px'],
      'heading-m': ['32px', '40px'],
      'heading-s': ['24px', '30px'],
      'heading-xs': ['21px', '28px'],
      'heading-xxs': ['18px', '24px'],
      'body-xl': ['24px', '32px'],
      'body-l': ['18px', '28px'],
      'body-m': ['16px', '24px'],
      'body-s': ['14px', '21px'],
      'tag-l': ['16px', '18px'],
      'tag-m': ['14px', '16px'],
      'tag-s': ['12px', '14px'],
      'mobile-display-xxl': ['48px', '56px'],
      'mobile-display-xl': ['40px', '48px'],
      'mobile-heading-l': ['32px', '40px'],
      'mobile-heading-m': ['24px', '32px'],
      'mobile-heading-s': ['21px', '28px'],
      'mobile-heading-xs': ['18px', '24px'],
      'mobile-heading-xxs': ['16px', '21px'],
      'pricing-body-l': ['18px', '24px'],
      'pricing-tag-xs': ['11px', '13px'],
      'mobile-pricing-heading-s': ['20px', '26px'],
      'mobile-pricing-body-l': ['18px', '24px'],
      'mobile-pricing-tag-s': ['12px', '14px'],
      'mobile-pricing-tag-xs': ['11px', '13px'],
      'static-mini': '11px',
    },
    fontWeight: {
      bold: '700',
      'semi-bold': '600',
      medium: '500',
      regular: '400',
    },
    letterSpacing: {
      extra: '0.15em',
    },
    maxWidth: {
      // Useful for elements max-width for sm, md & lg
      // usage example: max-w-lg
      sm: '288px',
      md: '720px',
      lg: '1120px',
      7: '1280px',
    },
    minWidth: {
      // Useful for elements min-width for sm, md & lg
      // usage example: min-w-lg
      sm: '288px',
      md: '720px',
      lg: '1120px',
      full: '100%',
      screen: '100vw',
    },
    minHeight: {
      'm-500': '500px',
      full: '100%',
      screen: '100vh',
    },
    order: {
      first: '-9999',
      last: '9999',
      none: '0',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
    },
    screens: {
      // the screens values are going to be used
      // as breakpoints by the 'container'
      sm: '500px',
      md: '720px',
      lg: '1120px',
      shortHeight: { raw: '(max-height: 400px)' },
    },
    spacing: {
      0: '0',
      1: '8px',
      2: '16px',
      3: '24px',
      4: '32px',
      5: '40px',
      6: '48px',
      7: '56px',
      8: '64px',
      9: '72px',
      10: '80px',
      11: '88px',
      12: '96px',
    },
    width: {
      // icons width
      16: '16px',
      32: '32px',
      64: '64px',
      // defaults tailwind
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      auto: 'auto',
      full: '100%',
      fullscreen: '100vw',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
    },
  },
  corePlugins: {
    backgroundOpacity: true,
    accentColor: false,
    animation: false,
    aspectRatio: false,
    backdropBlur: true,
    backdropBrightness: true,
    backdropContrast: true,
    backdropFilter: true,
    backdropGrayscale: true,
    backdropHueRotate: true,
    backdropInvert: true,
    backdropOpacity: true,
    backdropSaturate: true,
    backdropSepia: true,
    backgroundAttachment: false,
    backgroundBlendMode: false,
    backgroundClip: false,
    backgroundOrigin: false,
    blur: false,
    borderCollapse: false,
    borderOpacity: false,
    borderSpacing: false,
    boxDecorationBreak: true,
    breakAfter: false,
    breakBefore: false,
    brightness: false,
    caretColor: false,
    clear: false,
    contrast: false,
    divideColor: false,
    divideOpacity: false,
    divideStyle: false,
    divideWidth: false,
    dropShadow: true,
    flexBasis: true,
    fontVariantNumeric: false,
    grayscale: true,
    hueRotate: false,
    invert: false,
    isolation: false,
    justifyItems: false,
    justifySelf: false,
    mixBlendMode: false,
    objectFit: false,
    objectPosition: false,
    outlineColor: false,
    outlineOffset: false,
    outlineWidth: false,
    overscrollBehavior: false,
    placeContent: false,
    placeSelf: false,
    placeholderColor: false,
    placeholderOpacity: false,
    resize: false,
    ringColor: false,
    ringOffsetColor: false,
    ringOffsetWidth: false,
    ringOpacity: false,
    ringWidth: false,
    saturate: false,
    scrollBehavior: true,
    scrollMargin: true,
    scrollPadding: false,
    scrollSnapAlign: false,
    scrollSnapStop: false,
    scrollSnapType: false,
    sepia: false,
    skew: false,
    space: false,
    stroke: true,
    strokeWidth: false,
    tableLayout: true,
    textDecorationColor: false,
    textDecorationStyle: false,
    textDecorationThickness: false,
    textIndent: false,
    textOpacity: false,
    textUnderlineOffset: false,
    touchAction: false,
    visibility: true,
  },
} satisfies Config;
