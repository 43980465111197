import { createContext, type ReactNode, useMemo } from 'react';

type GeneralContextType = {
  internalLinks: InternalLinks;
};

const defaultContextValue: GeneralContextType = {
  internalLinks: {},
};

export const GeneralContext = createContext<GeneralContextType>(defaultContextValue);

interface Props {
  children: ReactNode;
  internalLinks: InternalLinks;
}

export const GeneralContextProvider: React.FC<Props> = ({
  children,
  internalLinks: initialInternalLinks,
}) => {
  const value = useMemo(
    () => ({
      internalLinks: initialInternalLinks,
    }),
    [initialInternalLinks]
  );

  return <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>;
};

export default GeneralContext;
