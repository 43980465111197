const getInternalLinks = (pageTree: Queries.GetPageTreeQueryQuery): InternalLinks => {
  const links: InternalLinks =
    pageTree?.allKenticoLink?.nodes.reduce(
      (all, current) => ({
        ...all,
        [current.codename]: current.url,
      }),
      {}
    ) || {};

  return links;
};

export default getInternalLinks;
